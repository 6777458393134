<template>
  <div class="card">
    <div class="left-card">
      <h3>
        <span :style="{ color: data.type == 1 ? '#47C754' : '#DC4343' }">{{
          data.type == 1 ? lang.v.purchase : lang.v.sellOut
        }}</span
        >{{ lang.v.integral }}
      </h3>
      <div class="order-id">
        {{ data?.orderId ? 'ID ' + data.orderId : '' }}
      </div>
      <div class="amount">
        <span>{{ lang.v.unit }}</span
        >&nbsp;{{ data?.amount }}
      </div>
      <div class="count">
        <span>{{ data?.type == 1 ? lang.v.demand : lang.v.sell }}</span
        >{{ data?.integral }}{{ lang.v.integral }}
      </div>
    </div>
    <div class="right-card">
      <div class="create">
        {{ data?.createTime }}
      </div>
      <div class="pay-way">
        {{ data?.payWayName }}
      </div>
      <div class="time">
        <img src="~@/assets/img/iconTime.png" alt="" class="icon-time" />
        <span class="text">{{ timeLimit }}{{ lang.v.minute }}</span>
      </div>
      <div class="btn" @click="onGoDetail(data)" v-if="status">
        {{ lang.v.viewDetails }}
      </div>
      <div v-else class="cancle">{{ lang.v.overDelete }}</div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import dataCenter from '@/dataCenter';
import { defineProps, toRefs, computed } from 'vue';
import { lang } from '@/lang';
const router = useRouter();
const props = defineProps({
  data: {
    default: {},
  },
  timeLimit: {
    type: Number,
    default: 0,
  },
});

const { data, timeLimit } = toRefs(props);

const status = computed(() => {
  if ([3, 4].includes(data.value.status)) {
    return false;
  } else {
    return true;
  }
});
//跳转详情页
const onGoDetail = item => {
  dataCenter.payStatus.setDataPayStatus(item);
  router.push({
    path: '/myOrderDetail',
    query: {
      type: data.value?.type,
      id: data.value?.id,
      PeddingOrder: 1,
    },
  });
};
</script>

<style lang="less" scoped>
.card {
  background: #fff;
  border-radius: 0.08rem;
  margin-bottom: 0.1rem;
  padding: 0.12rem;
  display: flex;
  justify-content: space-between;
  h3 {
    font-size: 0.14rem;
    font-weight: 600;
    margin-bottom: 0.07rem;
  }
  .order-id {
    height: 0.18rem;
    margin-bottom: 0.06rem;
  }
  .amount {
    font-size: 0.22rem;
    color: #222;
    line-height: 0.32rem;
    margin-bottom: 0.05rem;
    span {
      font-size: 0.12rem;
    }
  }
  .count {
    font-size: 0.12rem;
    span {
      color: #858c9f;
    }
  }
  .right-card {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .create {
      font-size: 0.12rem;
      line-height: 0.18rem;
      margin-bottom: 0.04rem;
    }
    .pay-way {
      height: 0.16rem;
      margin-bottom: 0.08rem;
      padding-right: 0.04rem;
      font-weight: 400;
      font-size: 0.11rem;
      color: #858c9f;
      border-radius: 0.01rem;
      border-right: 0.03rem solid #67d234;
    }
    .time {
      margin-bottom: 0.1rem;
      .icon-time {
        width: 0.12rem;
        height: 0.12rem;
      }
      .text {
        padding: 0.02rem;
        font-weight: 400;
        font-size: 0.12rem;
        color: #858c9f;
        line-height: 0.16rem;
        border-bottom: 0.01rem #858c9f dashed;
      }
    }

    .btn {
      width: 0.92rem;
      height: 0.34rem;
      background: #ed931e;
      border-radius: 0.06rem;
      font-size: 0.12rem;
      line-height: 0.34rem;
      color: #ffffff;
      text-align: center;
    }
  }
}
.cancle {
  margin-top: 0.1rem;
  font-size: 0.12rem;
  color: #858c9f;
}
</style>
